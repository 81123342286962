/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userTransactionHistory.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Details about this transaction and holds value of each custom field for this transaction 
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class TransactionDetails(
    @SerialName("external_order_id")
    val externalOrderId: String?,

    @SerialName("custom_field_1")
    val customField1: String?,

    @SerialName("custom_field_2")
    val customField2: String?,

    @SerialName("custom_field_3")
    val customField3: String?,

    @SerialName("custom_field_4")
    val customField4: String?,

    @SerialName("custom_field_5")
    val customField5: String?
)
