/**
 * NOTE: This code belongs to Kangaroo Rewards. Unauthorized use is prohibited
 */

@file:Suppress("ArrayInDataClass")
package features.userTransactionHistory.models

import kangaroorewards.appsdk.core.io.Model
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Transaction model containting transaction details
 */
@ExperimentalJsExport
@JsExport
@Serializable
data class Transaction(
    /** Unique transaction ID. */
    @SerialName("id")
    val id: Int?,

    /** The amount in $ for this transaction. */
    @SerialName("amount")
    val amount: Double?,

    /** The amount in points for this transaction. */
    @SerialName("points")
    val points: Double?,

    /** The transaction label. */
    @SerialName("name")
    val name: String?,

    /** The transaction type ID. */
    @SerialName("transaction_type")
    val transactionType: Int?,

    /** Date the transaction was created. */
    @SerialName("created_at")
    val createdAt: String?,

    /** Date the transaction was last updated. */
    @SerialName("updated_at")
    val updatedAt: String?,

    @SerialName("transaction_details")
    val transactionDetails: TransactionDetails?
)
